<script lang="ts" setup>
import type { FilterOption } from '#gql/default'
import type { FiltersDataProviderCinemaForMovieFetchParams } from '@base/components/filters/data-provider/cinemas-for-movie.vue'
import type { FiltersDataProviderCinemasFetchParams } from '@base/components/filters/data-provider/cinemas.vue'
import type { FiltersDataProviderMoviesFetchParams } from '@base/components/filters/data-provider/movies.vue'
import type { FiltersDataProviderProgramFetchParams } from '@base/components/filters/data-provider/program.vue'
import type { FiltersDataProviderShowGroupsFetchParams } from '@base/components/filters/data-provider/show-groups.vue'
import { URL_FILTER } from '@base/constants/url-filter'

export interface FiltersProps {
  urlPrefix?: string
  fetchComponent: any
  fetchParams:
    | FiltersDataProviderCinemasFetchParams
    | FiltersDataProviderShowGroupsFetchParams
    | FiltersDataProviderMoviesFetchParams
    | FiltersDataProviderProgramFetchParams
    | FiltersDataProviderCinemaForMovieFetchParams
  preselected?: {
    cinemaIds?: string[]
    auditoriums?: string[]
    contentRatings?: string[]
    languageFlags?: string[]
    technologyFlags?: string[]
    eventFlags?: string[]
    miscellaneousFlags?: string[]
    genres?: string[]
    periods?: string[]
    city?: string
    search?: string
    dates?: string[]
  }
  filters?: UrlFilter[]
  searchPlaceholder?: string
}

const {
  urlPrefix = '',
  filters = [],
  preselected = {},
  searchPlaceholder = '',
} = defineProps<FiltersProps>()

const { isCityFilterVisible, isSearchFilterVisible } =
  useFilterVisibility(filters)

function getAvailableOptions(filterOptions: FilterOption[]) {
  return filterOptions.filter(
    (option: FilterOption) =>
      (filters?.includes(option.key as UrlFilter) &&
        option.key !== URL_FILTER.CINEMA_IDS) ||
      (option.key === URL_FILTER.CINEMA_IDS && option.values.length > 1),
  )
}

function getPreselectedValues(filterKey: string) {
  const filterMap: { [key: string]: string | string[] | undefined } = {
    [URL_FILTER.GENRES]: preselected?.genres,
    [URL_FILTER.PERIODS]: preselected?.periods,
    [URL_FILTER.CONTENT_RATINGS]: preselected?.contentRatings,
    [URL_FILTER.LANGUAGE]: preselected?.languageFlags,
    [URL_FILTER.TECHNOLOGY]: preselected?.technologyFlags,
    [URL_FILTER.EVENT]: preselected?.eventFlags,
    [URL_FILTER.MISCELLANEOUS]: preselected?.miscellaneousFlags,
    [URL_FILTER.CINEMA_IDS]: preselected?.cinemaIds,
    [URL_FILTER.AUDITORIUMS]: preselected?.auditoriums,
    [URL_FILTER.SEARCH]: preselected?.search,
    [URL_FILTER.DATES]: preselected?.dates,
  }

  return filterMap[filterKey] ?? undefined
}

function useFilterVisibility(filters: UrlFilter[]) {
  const isCityFilterVisible = computed(() => filters?.includes(URL_FILTER.CITY))
  const isSearchFilterVisible = computed(() =>
    filters?.includes(URL_FILTER.SEARCH),
  )

  return {
    isCityFilterVisible,
    isSearchFilterVisible,
  }
}

defineOptions({
  name: 'Filters',
})
</script>

<template>
  <KCarousel>
    <Suspense>
      <component :is="fetchComponent" :url-prefix :fetch-params :filters>
        <template #default="{ filterOptions, dates }">
          <FiltersSearch
            v-if="isSearchFilterVisible"
            :url-prefix
            :collapse="true"
            :placeholder="searchPlaceholder"
            :preselected="getPreselectedValues(URL_FILTER.SEARCH)"
          />

          <FiltersCity
            v-if="isCityFilterVisible"
            :url-prefix
            :use-geo-location="false"
            :preselected-city-slug="preselected?.city"
          />

          <template
            v-for="filterOption in getAvailableOptions(filterOptions)"
            :key="filterOption.key"
          >
            <FiltersDates
              v-if="filterOption.key === URL_FILTER.DATES"
              :url-prefix
              :disabled="
                filterOptions.dates?.length === 1 || dates?.length === 1
              "
              :preselected="getPreselectedValues(URL_FILTER.DATES)"
              :filter-option
            />
            <FiltersGeneric
              v-else
              :key="filterOption.key"
              :url-prefix
              :filter-option
              :preselected="getPreselectedValues(filterOption.key as UrlFilter)"
            />
          </template>
        </template>
      </component>
      <template #fallback>
        <FiltersSkeleton v-for="key in filters.length" :key />
      </template>
    </Suspense>
  </KCarousel>
</template>
